<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" @click="showNav = false" to="/"
        >JBWW!
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navMenu"
        @click="showNav = !showNav"
        v-bind:class="{ 'is-active': showNav }"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navMenu"
      class="navbar-menu"
      v-bind:class="{ 'is-active': showNav }"
    >
      <div class="navbar-start">
        <router-link
          class="navbar-item"
          @click="showNav = false"
          to="/characters"
          >Characters</router-link
        >
        <router-link class="navbar-item" @click="showNav = false" to="/metadata"
          >Metadata</router-link
        >
      </div>
    </div>
  </nav>

  <section class="hero is-link">
    <div class="hero-body">
      <p class="title">J-Bitch</p>
      <p class="subtitle">Wasteland Warrior</p>
    </div>
  </section>
  <router-view />
  <footer class="footer">
    <div class="content">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            Copyright 2022-2023&nbsp;
            <a href="mailto:jbtchwasteland@gmail.com" target="_blank">
              Eugene Thompson
            </a>
            &nbsp;All rights reserved
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <a href="https://twitter.com/JBWWarrior" target="_blank"
              ><img
                :src="`${base_url}twitter.png`"
                alt="twitter.com"
                target="_blank"
                style="max-height: 25px"
            /></a>
          </div>
          <div class="level-item">
            <a href="https://www.instagram.com/jbwwarrior/" target="_blank"
              ><img
                :src="`${base_url}instagram.png`"
                alt="instagram.com"
                target="_blank"
                style="max-height: 25px"
            /></a>
          </div>
          <div class="level-item">
            <a href="https://www.youtube.com/channel/UCOTlxggTECxlOHBGCkH1SBw" target="_blank"
              ><img
                :src="`${base_url}youtube.png`"
                alt="youtube.com"
                target="_blank"
                style="max-height: 25px"
            /></a>
          </div>
          <div class="level-item">
            <a href="https://www.buymeacoffee.com/jbtchwastep" target="_blank"
              ><img
                :src="`${base_url}bmc-logo.png`"
                alt="buymeacoffee.com"
                target="_blank"
                style="max-height: 25px"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showNav: false,
    };
  },
  computed: {
    base_url() {
      return process.env.BASE_URL;
    },
  },
};
</script>
