import * as axios from 'axios';
import { API } from './config';

const show_latest = async() => {
  return await make_get_delete_request('get', `/latest`);
};

const show_seasons = async () => {
  return await make_get_delete_request('get', `/seasons`);
};

const show_season = async (season_id) => {
  return await make_get_delete_request('get', `/seasons/${season_id}`);
};

const show_episode = async (season_id, episode_id) => {
  return await make_get_delete_request('get', `/seasons/${season_id}/episodes/${episode_id}`);
};

const get_delete_request = async (verb, route) => {
  return await axios[verb](`${API}${route}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    crossdomain: true,
    data: {}
  });
};

const make_get_delete_request = async (verb, route, store) => {
  let response = await get_delete_request(verb, route, store);

  if (response.status !== 200) {
    console.log(response);
    throw Error(response.message);
  }

  return response.data;
};


export const data_service = {
  show_latest,
  show_seasons,
  show_season,
  show_episode
};
